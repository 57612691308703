* {
  box-sizing: border-box;
  /* border: 1px solid red; */
}

body {
  margin: 0; 
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.title {
  font-family: 'Lato', sans-serif;
  font-size: 72px;
}

@media screen and (max-width: 480px)
{
  .title{
    font-size: 48px;
  }
}

.title.n {
  color: #ffff00;
}

.title.f {
  color: #222;
}

.title.i {
  color: #fff;
}

.root {
  height: 100vh;
  width: 100vw;
  background-color: #707070;
  display: flex;
  justify-content: center;
}

.title-container{
  display: inline-flex;
  justify-content: center;
  width:100%;
}

.container {
  margin:0;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #808080;
  height: 100vh;
  width: 600px;
  padding: 0px 50px;
}

@media screen and (max-width: 480px)
{
  .container {
    margin:0;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: #808080;
    height: 100vh;
    width: 100%;
  }
}

.game-container{
  width: 480px;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 480px) {
  .game-container{
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns */
  grid-template-rows: repeat(4, 1fr);    /* 4 rows */
  gap: 10px; /* Adjust the gap as needed */
}

.circle-grid-container { 
  display: grid;
  padding: 10px;
  grid-template-columns: repeat(4, 1fr); /* 3 columns */
  grid-template-rows: repeat(4, 1fr);    /* 3 rows */
  gap: 10px; /* Adjust the gap as needed */
}

/* Style for squares in the grid */
.grid-item {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.tmp-square {
  width: 100px;
  height: 100px;
  text-align: center;
  display: grid;
  padding: 5px;
  grid-template-columns: repeat(4, 1fr); /* 3 columns */
  grid-template-rows: repeat(4, 1fr);    /* 3 rows */
  gap: 0px; /* Adjust the gap as needed */

  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.15s;
  transition-duration: 0.15s;
  -webkit-transition-property: transform;
  transition-property: transform;
}

@media screen and (max-width: 480px) {
  .tmp-square{
  width: 80px;
  height: 80px;}
}

.tmp-square:hover, .tmp-square:focus{
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.tmp-square:active {
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}

.tmp-square.n {
  background-color: #ffff00;
}

.tmp-square.f {
  background-color: #222;
}

.tmp-square.f.glow-on,
  .tmp-square.n.glow-on {
    -webkit-box-shadow:0px 0px 7x 4px rgba(255,255,0,0.6);
    -moz-box-shadow: 0px 0px 7px 4px rgba(255,255,0,0.6);
    box-shadow: 0px 0px 7px 4px rgba(255,255,0,0.6);
  }

.tmp-square.f.glow-off,
  .tmp-square.n.glow-off {
    -webkit-box-shadow:0px 0px 7px 4px rgba(0,0,0,0.6);
    -moz-box-shadow: 0px 0px 7px 4px rgba(0,0,0,0.6);
    box-shadow: 0px 0px 7px 4px rgba(0,0,0,0.6);
  }

/* The circles in the squares */
.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border-width: 2px;
  border-color: black;
  border-style: solid;
  visibility: hidden;
}

@media screen and (max-width: 480px) {
  .circle{
    width: 16px;
    height: 16px;
  }
}

.circle.o { 
  visibility:visible;
  background-color: #fff;
}

.circle.n { 
  visibility:visible;
  background-color: #ffff00;
}

.circle.f { 
  visibility:visible;
  background-color: #222;
}

  /************************************
  *************************************
               BUTTONS
  *************************************
  ************************************/

.primary-button {
  background-color:#ffff00;
  border-radius:12px;
  border:3px solid #fff426;
  display:inline-block;
  cursor:pointer;
  color:#333333;
  font-family:Arial;
  font-size:17px;
  font-weight:bold;
  padding:9px 26px;
  text-decoration:none;
  width: 425px;
  text-align: center;
  padding: auto auto;
  margin: 10px 0 0 0;
}

@media screen and (max-width: 480px) {
  .primary-button {
    width: 256px;
    font-size: 14px;
  }
}

.primary-button:hover {
  background-color:#fff424;
}
.primary-button:active {
  position:relative;
  top:1px;
}

.primary-button.undo.inactive{ 
  background-color: #e6e6bf;
  border-color: #cacaca;
  color: #848484;
}

.primary-button.undo.inactive:active{ 
  top:0px;
}

.primary-button.undo.active { 
  position:relative;
  top: 0px;
}


.secondary-button {
  background-color:#ffffeb;
  border-radius:12px;
  border:3px solid #fff426;
  display:inline-block;
  cursor:pointer;
  color:#333333;
  font-family:Arial;
  font-size:14px;
  font-weight:bold;
  padding:5px 26px;
  margin: auto 5px;
  text-decoration:none;
  width: 190px;
  text-align: center;
}

.secondary-button-debug {
  background-color:#ffa45e;
  border-radius:12px;
  display:inline-block;
  cursor:pointer;
  color:#333333;
  font-family:Arial;
  font-size:14px;
  font-weight:bold;
  padding:5px 26px;
  margin: auto 5px;
  text-decoration:none;
  width: 190px;
  text-align: center;
}

@media screen and (max-width: 480px) {
  .secondary-button {
    width: 140px;
    height: 30px;
    font-size: 12px;
    padding:4px 15px;
  }
}

.button-container-top {
  margin: 0 0 10px 0;
}

.secondary-button:hover {
  background-color:#fffaad;
}
.secondary-button:active {
  position:relative;
  top:1px;
}
